import { ToastContainer, toast } from "react-toastify";

require("dotenv").config();

const Web3 = require('web3');

const web3 = new Web3("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161");

const contractABI = require("../contract-abi.json");
const contractAddress = "0x2d28dc8a5e4c0cef6abc89dca06a85c705346ad0";


let theTxHash = "";
let transactionStatus = false;
let theToken = 0;


async function txWatchdog() {

    let status = false;

    try
    {
        const transactionHash = await web3.eth.getTransactionReceipt(theTxHash);

        let transaction = transactionHash;
        let logs = transactionHash.logs;
        let token = web3.utils.hexToNumber(logs[0].topics[3]);


        	toast.success("NFT Minted Successfully!",{ autoClose: 7000 });

		theToken = token;
		transactionStatus = true;
		
    }
    catch{
        setTimeout(txWatchdog,5000);
    }

}

export const getUserImages = async (addr) => {
	const userImages = await new web3.eth.Contract(contractABI,contractAddress).methods.getClaimable(addr).call();
	return {
		tokens: userImages
	}
}


export const getClaimStatus = async (token) => {
	const status = await new web3.eth.Contract(contractABI,contractAddress).methods.tokenClaimable(token).call();
	return {
		status: status
	}
}



export const verifyChainId = async () => {

	return {
		status: true,
	};
}

export const getMintingStatus = async () => {
    const mintingStatus = await new web3.eth.Contract(
		contractABI,
		contractAddress
	).methods
		.hasSaleStarted()
		.call();
        return{mintingStatus: mintingStatus};
}

export const getBasicInformations = async () => {

	const allInformations = await new web3.eth.Contract(contractABI,contractAddress).methods.getInformations().call();
	if(allInformations.indexOf(",")>0)
	{
		const myArray = allInformations.split(",");

		const currentRoundName = myArray[0];
		const currentSupply = myArray[1];
		const currentTime = myArray[2];
		const currentCost = myArray[3];
		const maxMintAmount = myArray[4];
		const roundPassword = myArray[5];
		const maxAddressAmount = myArray[6];



		/*const currentRoundName = await new web3.eth.Contract(contractABI,contractAddress).methods.CURR_ROUND_NAME().call();
		const currentSupply = await new web3.eth.Contract(contractABI,contractAddress).methods.CURR_ROUND_SUPPLY().call();
		const currentCost = await new web3.eth.Contract(contractABI,contractAddress).methods.CURR_MINT_COST().call();
		const maxMintAmount = await new web3.eth.Contract(contractABI,contractAddress).methods.maxMintAmount().call();
		const currentTime = await new web3.eth.Contract(contractABI,contractAddress).methods.CURR_ROUND_TIME().call();*/

		return {
			currentCost: 	web3.utils.fromWei(currentCost),
			currentSupply: currentSupply,
			maxMintAmount: maxMintAmount,
			currentTime: currentTime,
			currentRoundName: currentRoundName,
			roundPassword: roundPassword
		};
		//alert(myArray[0]);
	}



};


export const connectWallet = async () => {
    if (window.ethereum) {
	try {
		const addressArray = await window.ethereum.request({
			method: "eth_requestAccounts",
		});
		const obj = {
			status: "Wallet connected successfully",
			address: addressArray[0],
		};
		return obj;
		} catch (err) {
		return {
			address: "",
			status: "😥 " + err.message,
		};
		}


    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

export const getCurrentWalletConnected = async () => {

	if (window.ethereum) {
		try {
			const addressArray = await window.ethereum.request({
				method: "eth_accounts",
			});

			if (addressArray.length > 0) {
				return {
					address: addressArray[0],
					status: "Wallet connected successfully",
				};
			} else {
				return {
					address: "",
					status: "Connect to Metamask using the top right button.",
				};
			}
		} catch (err) {
			return {
				address: "",
				status: "😥 " + err.message,
			};
		}
	} else {
		return {
			address: "",
			status: (
				<span>
					<p>
						{" "}
						🦊{" "}
						<a
							target="_blank"
							href={`https://metamask.io/download.html`}
						>
							You must install Metamask, a virtual Ethereum
							wallet, in your browser.
						</a>
					</p>
				</span>
			),
		};
	}
};


export const getTxStatus = () => {
	return {txStatus: transactionStatus};
}

export const setTxStatus = () => {
	transactionStatus = false;
}
export const getToken = () => {
	return {token: theToken};
}

export const claimBabyShark = async(token1, token2) => {

	claimNFT(token1,token2);
}

export const claimNFT = async (token1, token2) => {

	window.contract = await new web3.eth.Contract(contractABI, contractAddress);

	const theABIData = window.contract.methods
		.claimBabyShark(token1,token2)
		.encodeABI();
	
	let errorCame = false;
	let theError = "";
	let gasEst = "";

	try
	{
		 gasEst = await web3.eth.estimateGas({from: window.ethereum.selectedAddress,to: contractAddress,data: theABIData
		},function(err,res){
			alert("ERROR: " + err);
		},function(error, res){
			if(error != null)
			{
				errorCame = true;
				theError = error;
				//alert(error);
			}
			else
			{
				//alert(res);
			}

			})
	}
	catch(err)
	{
		console.log(err);
	}

	if(errorCame == true)
	{
		return {
			success: false,
			status: theError,
		};
	}

	const transactionParameters = {
		to: contractAddress, 
		from: window.ethereum.selectedAddress, 
		data: theABIData,
	};

	try {
		const txHash = await window.ethereum.request({
			method: "eth_sendTransaction",
			params: [transactionParameters],
		});
        theTxHash = txHash;
        setTimeout(txWatchdog,1000);;
		return {
			success: true,
			status: "NFT Claim in process...",
		};
	} catch (error) {

		if(window.location.toString().indexOf("claim") > 0)
		{
			window.$('.card-claim-selected-area').removeClass('animate');
			setTimeout(function () {
				window.$('.heart.filled').fadeOut();
				window.$('.heart.blank').fadeIn();
	
			}, 1000);
		}



		return {
			success: false,
			status: "Something went wrong: " + error.message,
		};
	}
}



export const mintNFT = async (mintAmount, mintCost) => {

    toast.info("Minting..");
	const tokenURI = "";
	window.contract = await new web3.eth.Contract(contractABI, contractAddress); //loadContract();
	
	let theAmount = (mintAmount * mintCost).toString();
	

	const amountToSend = web3.utils.toWei(theAmount, "ether");

	const theABIData = window.contract.methods
		.mintNFT(mintAmount)
		.encodeABI();

	
	let errorCame = false;
	let theError = "";
	let gasEst = "";

	try
	{
		 gasEst = await web3.eth.estimateGas({
			from: window.ethereum.selectedAddress,
			//nonce: nonce,
			to: contractAddress,
			data: theABIData,
			value: web3.utils.toHex(amountToSend),
		},function(err,res){
			alert("ERROR: " + err);
		},function(error, res){
			if(error != null)
			{
				errorCame = true;
				theError = error;
				alert(error);
			}
			else
			{
				//alert(res);
			}

			})
	}
	catch(err)
	{
		console.log(err);
	}

	if(errorCame == true)
	{
		return {
			success: false,
			status: theError,
		};
	}

	const transactionParameters = {
		to: contractAddress,
		from: window.ethereum.selectedAddress,
		value: web3.utils.toHex(amountToSend),
		data: theABIData,
	};

	try {
		const txHash = await window.ethereum.request({
			method: "eth_sendTransaction",
			params: [transactionParameters],
		});
        theTxHash = txHash;
        setTimeout(txWatchdog,1000);;
		return {
			success: true,
			status: "NFT Minting in process...",
		};
	} catch (error) {
		return {
			success: false,
			status: "Something went wrong: " + error.message,
		};
	}
};
