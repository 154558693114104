import './App.css';
import Home from './Home'
import Minter from './Minter'
import Claim from './Claim'
//import Buy from './Buy'

function App() {

  /*let thePage = "";
  if(window.location.toString().indexOf("mint") > 0)
  {
    thePage = <Minter/>;
  }
  else if(window.location.toString().indexOf("claim") > 0)
  {
    //thePage = <Claim/>;
    thePage = <Home/>;
  }
  else
  {
    thePage = <Home/>;
  }

*/

  return (
    <div className="App">
      <Minter/>
    </div>
  );
}






export default App;
